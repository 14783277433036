import {
  Box,
  Heading,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Select,
  RadioGroup,
  Radio,
  useBreakpointValue,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import MultiplexAd from "../../components/ads/multiplex-ad"
import MultiplexVerticalAd from "../../components/ads/multiplex-vertical-ad"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { LivingSpan } from "../../constants/living-span"

type TaxPayload = {
  incomeTax: number
  specialTax: number
  residentTax: number
  total: number
}

const PropertiesTaxSimulator: React.FC<any> = ({ data, location: loc }) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: true,
    lg: true,
    xl: false,
  })
  const path = loc?.pathname || ""
  const [isMyHome, setIsMyHome] = useState<string>("1")
  const [salesPrice, setSalesPrice] = useState<number>(0)
  const [salesCost, setSalesCost] = useState<number>(0)
  const [buyingPrice, setBuyingPrice] = useState<number>(0)
  const [buyingCost, setBuyingCost] = useState<number>(0)
  const [livingSpan, setLivingSpan] = useState<LivingSpan>(
    LivingSpan.LESS_THAN_FIVE
  )
  const [incomeTax, setIncomeTax] = useState<number>(0)
  const [specialTax, setSpecialTax] = useState<number>(0)
  const [residentTax, setResidentTax] = useState<number>(0)
  const [taxTotal, setTaxTotal] = useState<number>(0)

  useEffect(() => {
    const {
      total: taxRate,
      incomeTax,
      specialTax,
      residentTax,
    }: TaxPayload = getTaxRate(livingSpan)

    const myhomeReduction = isMyHome === "1" ? 3000 : 0

    const proficialAmount: number =
      salesPrice - salesCost - buyingPrice - buyingCost - myhomeReduction

    const incomeTaxAmount: number = Number(
      (proficialAmount * incomeTax).toFixed(2)
    )
    const specialTaxAmount: number = Number(
      (proficialAmount * specialTax).toFixed(2)
    )
    const residentTaxAmount: number = Number(
      (proficialAmount * residentTax).toFixed(2)
    )
    const tax: number = Number((proficialAmount * taxRate).toFixed(2))

    setTaxTotal(tax)
    setIncomeTax(incomeTaxAmount)
    setSpecialTax(specialTaxAmount)
    setResidentTax(residentTaxAmount)
  }, [salesPrice, livingSpan, salesCost, buyingPrice, buyingCost, isMyHome])

  const getTaxRate = (livingSpan: LivingSpan): TaxPayload => {
    switch (livingSpan) {
      case LivingSpan.LESS_THAN_FIVE:
        return {
          incomeTax: 0.3,
          specialTax: 0.0063,
          residentTax: 0.09,
          total: 0.3963,
        }
      case LivingSpan.MORE_THAN_FIVE:
        return {
          incomeTax: 0.15,
          specialTax: 0.00315,
          residentTax: 0.05,
          total: 0.20315,
        }
      case LivingSpan.MORE_THAN_TEN:
        return {
          incomeTax: 0.1,
          specialTax: 0.0021,
          residentTax: 0.04,
          total: 0.1421,
        }
      default:
        return {
          incomeTax: 0.3,
          specialTax: 0.0063,
          residentTax: 0.09,
          total: 0.3963,
        }
    }
  }

  const onHandleLivingSpanChange = (ev: any) => {
    switch (ev.target.value) {
      case LivingSpan.LESS_THAN_FIVE:
        setLivingSpan(LivingSpan.LESS_THAN_FIVE)
        break
      case LivingSpan.MORE_THAN_FIVE:
        setLivingSpan(LivingSpan.MORE_THAN_FIVE)
        break
      case LivingSpan.MORE_THAN_TEN:
        setLivingSpan(LivingSpan.MORE_THAN_TEN)
        break
      default:
        setLivingSpan(LivingSpan.LESS_THAN_FIVE)
        break
    }
  }

  return (
    <Layout>
      <Box pb={{ base: 8, lg: 24 }} pt={36} px={{ base: 4, lg: 8 }}>
        <Seo title="不動産売却税金計算ツール" />
        <Heading
          as={`h1`}
          mb={10}
          fontWeight="bold"
          fontSize={24}
          textAlign="center"
        >
          不動産売却 税金シミュレータ
        </Heading>
        <Flex align={`center`} flexDirection={`column`}>
          <Stack
            direction={{ base: "column", md: `row` }}
            w={{ base: "100%", lg: `800px` }}
          >
            <Stack w={{ base: "100%", md: `50%` }} p={4} spacing={6}>
              <FormControl>
                <FormLabel htmlFor="name" fontSize={13}>
                  譲渡価格（売却額）は?
                </FormLabel>
                <Stack direction={`row`} align={`end`}>
                  <Input
                    id="name"
                    placeholder="譲渡価格を入力してください"
                    size={`sm`}
                    fontSize={12}
                    w={`80%`}
                    onChange={ev => setSalesPrice(Number(ev.target.value))}
                  />
                  <Text fontSize={12}>万円</Text>
                </Stack>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name" fontSize={13}>
                  譲渡時にかかった諸経費は?
                </FormLabel>
                <Stack direction={`row`} align={`end`}>
                  <Input
                    id="name"
                    placeholder="諸経費の総額を入力してください"
                    size={`sm`}
                    fontSize={12}
                    w={`80%`}
                    onChange={ev => setSalesCost(Number(ev.target.value))}
                  />
                  <Text fontSize={12}>万円</Text>
                </Stack>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name" fontSize={13}>
                  取得価格は?
                </FormLabel>
                <Stack direction={`row`} align={`end`}>
                  <Input
                    id="name"
                    placeholder="購入時の価格を入力してください"
                    size={`sm`}
                    fontSize={12}
                    w={`80%`}
                    onChange={ev => setBuyingPrice(Number(ev.target.value))}
                  />
                  <Text fontSize={12}>万円</Text>
                </Stack>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name" fontSize={13}>
                  取得時にかかった諸経費は？
                </FormLabel>
                <Stack direction={`row`} align={`end`}>
                  <Input
                    id="name"
                    placeholder="購入時にかかた費用の額を入力してください"
                    size={`sm`}
                    fontSize={12}
                    w={`80%`}
                    onChange={ev => setBuyingCost(Number(ev.target.value))}
                  />
                  <Text fontSize={12}>万円</Text>
                </Stack>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name" fontSize={13}>
                  譲渡した年の1月1日において所有していた期間は?
                </FormLabel>
                <Select
                  placeholder="所有期間を選んでください"
                  size={`sm`}
                  fontSize={12}
                  onChange={onHandleLivingSpanChange}
                  value={livingSpan}
                >
                  <option value="LESS_THAN_FIVE">5年以下</option>
                  <option value="MORE_THAN_FIVE">5年超え</option>
                  <option value="MORE_THAN_TEN">10年超え</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name" fontSize={13}>
                  マイホームですか？
                </FormLabel>
                <RadioGroup onChange={setIsMyHome} value={isMyHome} size={`sm`}>
                  <Stack direction="row">
                    <Radio value="1">マイホームです</Radio>
                    <Radio value="2">マイホームではないです</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack p={4} w={{ base: "100%", md: `50%` }} spacing={4}>
              <Box w={`100%`}>
                <Heading fontSize={16} mb={1}>
                  ① 支払いが必要な所得税
                </Heading>
                <Box bg={`blackAlpha.50`} p={1} px={2}>
                  <Text fontSize={13} color={`blackAlpha.700`}>
                    {incomeTax}万円
                  </Text>
                </Box>
              </Box>
              <Box w={`100%`}>
                <Heading fontSize={16} mb={1}>
                  ② 支払いが必要な復興特別所得税
                </Heading>
                <Box bg={`blackAlpha.50`} p={1} px={2}>
                  <Text fontSize={13} color={`blackAlpha.700`}>
                    {specialTax}万円
                  </Text>
                </Box>
              </Box>
              <Box w={`100%`}>
                <Heading fontSize={16} mb={1}>
                  ③ 支払いが必要な住民税
                </Heading>
                <Box bg={`blackAlpha.50`} p={1} px={2}>
                  <Text fontSize={13} color={`blackAlpha.700`}>
                    {residentTax}万円
                  </Text>
                </Box>
              </Box>
              <Box w={`100%`}>
                <Heading fontSize={16} mb={1}>
                  税金総額（①+②+③）
                </Heading>
                <Box bg={`blackAlpha.50`} p={1} px={2}>
                  <Text fontSize={13} color={`blackAlpha.700`}>
                    {taxTotal}万円
                  </Text>
                </Box>
              </Box>
              {isMyHome === "1" && (
                <Box bg={`green.50`} p={2}>
                  <Text fontSize={12} color={`green.800`}>
                    マイホーム特別控除（3000万円）が受けられます
                  </Text>
                </Box>
              )}
              {taxTotal < 0 && (
                <>
                  <Box bg={`green.50`} p={2}>
                    <Text fontSize={12} color={`green.800`}>
                      確定申告は必要ありません
                    </Text>
                  </Box>
                  <Box bg={`green.50`} p={2}>
                    <Text fontSize={12} color={`green.800`}>
                      税金の支払いは必要ありません
                    </Text>
                  </Box>
                </>
              )}
            </Stack>
          </Stack>
          <Stack w={{ base: "100%", lg: `800px` }} py={4}>
            {isMobile ? (
              <MultiplexVerticalAd path={path} />
            ) : (
              <MultiplexAd path={path} />
            )}
          </Stack>
        </Flex>
      </Box>
    </Layout>
  )
}

export default PropertiesTaxSimulator

export const query = graphql`
  {
    allContentfulSiteImages(filter: { location: { eq: "404" } }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
  }
`
